import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {fromProfile} from 'store/selectors';
import ComponentForm from '../../../../forms/ComponentForm';
import AdvModal from 'containers/AdvModal';

const getFormName = ({component, componentType}) =>
  component ? `componentUpdate${component.id}` : `componentAdd${componentType.slug}`;

// format schedule values for MultiSelect
const parseConfig = (config) => {
  if (config && config.schedule && config.schedule.minutes) {
    config.schedule.minutes.sort();
    config.schedule = config.schedule.minutes.map((minute) => ({
      id: minute,
      name: `:${String(minute).padStart(2, '0')}`,
    }));
  }

  return config;
};

const getInitialValues = ({component, activeVersion, activeLink}) =>
  component
    ? {
        ...component,
        image: activeVersion.image,
        link: activeLink ? activeLink.target : undefined,
        message: activeVersion.message,
        config: parseConfig(activeVersion.config),
      }
    : undefined;

const getAdditionalData = ({activeVersion}) =>
  activeVersion
    ? {
        created: activeVersion.created,
      }
    : undefined;

const getComponentForm = (data) => {
  let fields = [];
  let optionalFields = [];

  switch (data.componentType.slug) {
    case 'live-graphics':
      fields = ['name', 'image', 'date_range_option', 'impression_cutoff', 'daily_impression_cap'];
      optionalFields = ['date_range'];

      if (data.campaign.set_live_graphic_times) {
        fields.push('duration');
      }
      if (data.campaign.pause_after_live_graphics) {
        fields.push('delay');
      }
      break;
    case 'banner-graphics':
      fields = ['name', 'link', 'image', 'date_range_option'];
      optionalFields = ['date_range'];
      break;
    case 'twitch-chat-bot':
      fields = ['name', 'link-with-add', 'message', 'date_range_option', 'message_interval'];
      optionalFields = ['date_range'];
      break;
    case 'suggested-tweet':
      fields = ['name', 'link-with-add', 'message', 'image', 'is_editable', 'date_range_option'];
      optionalFields = ['date_range'];
      break;
    case 'suggested-retweet':
      fields = ['name', 'tweet', 'date_range_option'];
      optionalFields = ['date_range'];
      break;
    case 'twitch-chat-bot-command':
      fields = ['name', 'command', 'link-with-add', 'message', 'date_range_option'];
      optionalFields = ['date_range'];
      break;
    case 'dynamic-cta':
      fields = ['name'];
      break;
    case 'fullscreen-video':
      fields = ['name', 'video', 'youtube', 'chatbot-select', 'minute-select', 'date_range_option'];
      optionalFields = ['date_range'];
      break;
    case 'custom-link':
      fields = [
        'name',
        'description',
        'link',
        'is_fulfillable',
        'keyword_option',
        'link-preview',
        'date_range_option',
      ];
      optionalFields = ['source_keyword'];
      break;
    case 'advocate-widget':
      fields = ['name', 'widget_upload', 'date_range_option'];
      break;
    case 'conversion-popup':
      fields = ['name', 'message', 'image', 'color'];
      optionalFields = ['date_range'];
      break;
    case 'watch-party':
      fields = ['name', 'watch-party-select'];
      optionalFields = ['date_range'];
      break;
    default:
      return <p className="is-danger">Not a valid component type</p>;
  }

  return (
    <ComponentForm
      {...data}
      form={getFormName(data)}
      componentFields={fields}
      optionalFields={optionalFields}
      additionalData={data.additionalData}
      initialValues={data.initialValues}
    />
  );
};

const ComponentFormModal = ({modalName, contentLabel, ...props}) => {
  const userType = useSelector((state) => fromProfile.getUserType(state));

  const initialValues = useMemo(() => getInitialValues(props), [props]);
  const additionalData = useMemo(() => getAdditionalData(props), [props]);

  return (
    <AdvModal
      name={modalName}
      contentLabel={
        props.component ? `Update ${props.component.name}` : `Add New ${props.componentType.name}`
      }
    >
      {getComponentForm({modalName, userType, initialValues, additionalData, ...props})}
    </AdvModal>
  );
};

ComponentFormModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  componentType: PropTypes.object.isRequired,
  component: PropTypes.object,
  activeVersion: PropTypes.object,
  activeLink: PropTypes.object,
  contentLabel: PropTypes.string,
  campaign: PropTypes.object,
};

export default ComponentFormModal;
